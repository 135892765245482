// Override default variables before the import
$body-bg: #add271;
// $theme-colors: (
//   "primary": #afb42b,
// );
$black: #4e4e4e;
$btn-border-radius: 15px;

$btn-padding-x: 2rem;
$btn-padding-y: 0.5rem;
$btn-font-size: 1.1rem;

$input-border-radius: 20px;
$input-height: 48px;
$input-bg: #add271;
$input-color: #ffffff;
$input-border-color: #ffffff;
$input-border-width: 2px;
$input-font-weight: 600;
// $input-box-shadow: 0;
$input-placeholder-color: #fff;
// $font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
.btn-poke {
  @include button-variant(#ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff);
}

.btn-poke-outline {
  @include button-outline-variant(#ffffff, #ffffff, #ffffff, #ffffff);
}
